import ScrollToTop from "../../components/common/ScrollToTop";
import FooterSection from "../../components/legal/FooterSection";
import HeroHome from "../../components/legal/HeroHome";

const LegalNotices = () => {
  return (
    <>
      <ScrollToTop />
      <HeroHome />
      <FooterSection />
    </>
  );
};

export default LegalNotices;
