import { branch, referrer } from "../core/storage";
import { create } from "zustand";

interface IReferrerType {
  referrer: string;
  branch: string;
  setReferrer(next: string): any;
  setBranch(next: string): any;
  getUrl(): string;
  getUrlIBO(): string;
}

export const useReferrer = create<IReferrerType>((set, get) => {
  return {
    referrer: referrer.value || "",
    setReferrer(next) {
      set({ referrer: next });
      referrer.value = next;
      return undefined;
    },
    branch: branch.value || "",
    setBranch(next) {
      set({ branch: next });
      branch.value = next;
    },
    getUrl() {
      const onlineHost = "www.exaku.com";
      if (globalThis.window && window.location.host === onlineHost)
        return `https://app.exaku.com/auth/signin`;
      else return `https://exaku-bo-stg-03bb18a4a396.herokuapp.com/auth/signin`;
    },
    getUrlIBO() {
      const onlineHost = "www.exaku.com";
      const pathName = `${get().referrer}/leg/${get().branch}`;
      if (globalThis.window && window.location.host === onlineHost)
        return `https://app.exaku.com/refer/${pathName}`;
      else
        return `https://exaku-bo-stg-03bb18a4a396.herokuapp.com/refer/${pathName}`;
    },
  };
});
