import { create } from "zustand";
import Web3 from "web3";
import { Contract } from "ethers";
import { sequence } from "0xsequence";
import { useAccount } from "wagmi";

export interface Web3Connection {
  isLoading: boolean;
  client?: Web3;
  contract?: Contract;
  method?: "metamask" | "sequence" | "walletconnect";
  sequenceWallet?: sequence.provider.Wallet;
  sequenceConnection?: sequence.provider.ConnectDetails;
  setupWithMetamask(): Promise<void>;
  setupWithSequence(): Promise<sequence.provider.Wallet | undefined>;
  setupWithWalletconnect(params: ReturnType<typeof useAccount>): Promise<void>;
}

export const useWeb3Connection = create<Web3Connection>((set, _) => {
  return {
    isLoading: true,
    client: undefined,
    contract: undefined,
    sequenceWallet: undefined,
    setupWithMetamask: async () => {
      set({ isLoading: true, method: "metamask" });
      try {
        let client = new Web3(Web3.givenProvider);
        set({
          isLoading: false,
          client: client,
        });
      } catch (e) {
        console.log(e);
      } finally {
        set({ isLoading: false });
      }
    },
    async setupWithSequence() {
      set({ isLoading: true, method: "sequence" });
      try {
        let wallet = await sequence.initWallet("polygon");
        let connection = await wallet
          .connect({
            app: "Exaku Academy",
            authorize: true,
            settings: {
              theme: "dark",
              signInOptions: ["google", "apple", "facebook", "email"],
              includedPaymentProviders: ["moonpay", "ramp", "wyre"],
              defaultFundingCurrency: "matic",
              lockFundingCurrencyToDefault: false,
            },
          })
          .catch((err) => {
            console.log(err);
            return undefined;
          });
        if (connection) {
          let wallet = sequence.getWallet();
          set({ sequenceWallet: wallet, sequenceConnection: connection });
          let provider = await wallet.getProvider();
          if (provider) {
            let client = new Web3(provider as any);
            set({
              isLoading: false,
              client: client,
            });
          }
          return wallet;
        }
      } catch (e) {
        console.log(e);
      } finally {
        set({ isLoading: false });
      }
      return undefined;
    },
    async setupWithWalletconnect(params: ReturnType<typeof useAccount>) {
      set({ isLoading: true, method: "walletconnect" });
      try {
        let provider = await params.connector?.getProvider();
        if (provider) {
          let client = new Web3(provider);
          set({
            isLoading: false,
            client: client,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        set({ isLoading: false });
      }
    },
  };
});
