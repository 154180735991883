import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useReferrer } from "../../store/useReferrer";
import HeaderWithLogin from "../common/HeaderWithLogin";

const Maintenance = () => {
  const { getUrl } = useReferrer();

  const goToSigninPage = () => {
    window.location.assign(getUrl());
  };

  return (
    <VStack
      h={{ base: "100vh", md: "100vh" }}
      //   backgroundImage={`url(${Banner})`}
      backgroundPosition="top"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundColor="exakugray.100"
      overflow="hidden"
      position="relative"
    >
      <Box
        position="absolute"
        top="-1210px"
        bgGradient="linear-gradient(116deg, #FF4C4C 59.3%, #69C9FD 69.44%)"
        w="1316px"
        h="1210px"
        borderRadius="100%"
        filter="blur(299px)"
      />
      <Container maxW="container.xl">
        <HeaderWithLogin />
        <Center
          display="flex"
          flexDirection="column"
          color="white"
          mt={{ base: 20, md: 40 }}
          position="relative"
          zIndex={20}
        >
          <Heading
            fontSize={{ base: "35px", md: "70px" }}
            fontWeight={700}
            fontFamily="aileron"
            bgGradient="line"
            letterSpacing={3}
            textAlign="center"
          >
            Votre site est actuellement sous{" "}
            <Text
              as="span"
              style={{ textShadow: "0px 4px 27px rgba(255, 109, 109, 0.49)" }}
              color="exakugray.200"
              lineHeight="78px"
              letterSpacing={3}
            >
              maintenance
            </Text>
          </Heading>
          <Text
            as="p"
            width={590}
            textAlign="center"
            mt={{ base: 4, md: 8 }}
            color="exakugray.300"
            fontSize={16}
            fontWeight={400}
            w="100%"
            // fontFamily="Gordita Regular":
          >
            Toutes nos excuses pour le désagrément. Notre équipe est à{" "}
            <br className="hidden md:flex" /> pied d'oeuvre pour rendre votre
            site disponible à nouveau.
          </Text>
          <Button
            bg="exakured.100"
            color="white"
            fontWeight="700"
            borderRadius={12}
            fontSize={{ base: "20px", md: "24px" }}
            px={20}
            py={7}
            mt={8}
            _hover={{ bg: "exakured.100", opacity: 0.8 }}
            onClick={goToSigninPage}
          >
            Se connecter
          </Button>
        </Center>
      </Container>
    </VStack>
  );
};

export default Maintenance;
