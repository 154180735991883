import InstagramIcon from "../assets/images/intagram.svg";
import TwitterIcon from "../assets/images/twitter.svg";
import TelegramIcon from "../assets/images/telegram.svg";

export const socialNetworks = [
  {
    id: 1,
    title: "Instagram Icon",
    icon: InstagramIcon,
    href: "https://www.instagram.com/exakubusinessclub/",
  },
  {
    id: 2,
    title: "Twitter Icon",
    icon: TwitterIcon,
    href: "https://twitter.com/ExakuOfficial",
  },
  {
    id: 3,
    title: "Telegram Icon",
    icon: TelegramIcon,
    href: "https://t.me/exakubusinessclub",
  },
];

export const footerLinks = [
  {
    title: "Mentions légales",
    href: "/legal",
  },
  {
    title: "Politique de confidentialité",
    href: "/legal",
  },
  {
    title: "Risques et bonnes pratiques",
    href: "/legal",
  },
];
