import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import React from "react";

import { WagmiConfig } from "wagmi";
import { polygon, polygonMumbai } from "wagmi/chains";

// 1. Get projectId
const projectId = "1e765f44ae9469bd310225127c0e8900";

// 2. Create wagmiConfig
const metadata = {
  name: "Exaku Academy",
  url: "https://exaku.com",
};

// 2. Create wagmiConfig
const chains = [polygon, polygonMumbai];
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });

export default function WalletConnect({
  children,
}: {
  children: React.ReactNode;
}) {
  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
}
