import { Box, Container, Flex, Text, VStack } from "@chakra-ui/react";
import HeaderWithLogin from "../common/HeaderWithLogin";
import Banner from "../../assets/images/banner.svg";

const HeroHome = () => {
  return (
    <VStack
      minH="100vh"
      backgroundImage={`url(${Banner})`}
      backgroundPosition="top"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundColor="exakugray.100"
      overflow="hidden"
      position="relative"
    >
      <Container maxW="container.xl">
        <HeaderWithLogin />
        <VStack mt={{ base: "50px", md: "105px" }} mb="192px" color="white">
          <Box px={5} display="inline-flex" flexDirection="column">
            <Flex
              flexDirection="column"
              gap="30px"
              alignItems="center"
              w="100%"
            >
              <Text
                as="h1"
                fontFamily="aileron"
                fontSize="32px"
                color="white"
                fontWeight={700}
                lineHeight="60px"
                textAlign="center"
              >
                MENTIONS LÉGALES
              </Text>
            </Flex>
            <Flex
              className="w-full font-body flex flex-col items-center justify-start gap-4"
              mt={{ base: "50px", md: "105px" }}
            >
              <Box className="w-full flex flex-col items-start justify-start gap-2">
                <Text>
                  <b>OKAIWA LTD</b>
                  <br />
                  71-75 Shelton Street Covent Garden London WC2H 9JQ UNITED
                  KINGDOM
                </Text>
                <Flex className="flex flex-row items-center justify-center gap-2">
                  <Text className="font-bold uppercase">COMPANY NUMBER: </Text>
                  <Text>14641315</Text>
                </Flex>
                <Flex className="flex flex-row items-center justify-center gap-2">
                  <Text className="font-bold uppercase">Contact web: </Text>
                  <Text>support@exaku.com</Text>
                </Flex>
                <Text>
                  Le nom de domaine <b>exaku.com</b> appartient à la société
                  OKAIWA LTD.
                </Text>
              </Box>

              <Text>
                Toute reproduction ou distribution non autorisée de tout ou
                partie des éléments et informations de ce site est interdite. Le
                contenu est disponible pour un usage privé et non collectif. Le
                code de la propriété intellectuelle n’autorisant, aux termes de
                l’article L. 122-5.2° et 3°a, d’une part, que les “copies ou
                reproductions strictement réservées à l’usage privé du copiste
                et non destinées à une utilisation collective” et, d’autre part,
                que les analyses et les courtes citations dans un but d’exemple
                et d’illustration, “toute représentation ou reproduction
                intégrale ou partielle faite sans le consentement de l’auteur ou
                de ses ayants droit ou ayants cause est illicite” (art. L.
                122-4). Cette représentation ou reproduction, par quelque
                procédé que ce soit constituerait donc une contrefaçon
                sanctionnée par les articles L. 335-2 et suivants du code de la
                propriété intellectuelle.
              </Text>
              <Text>
                Conformément aux dispositions de la loi n° 98-536 du 1er juillet
                1998 portant transposition dans le code de la propriété
                intellectuelle de la directive 96/9 CE du 11 mars 1996
                concernant la protection juridique des bases de données, OKAIWA
                LTD est producteur et propriétaire de tout ou partie des bases
                de données composant le présent site. En accédant au présent
                site, vous reconnaissez que les données le composant sont
                légalement protégées, et, conformément aux dispositions de la
                loi du 01/07/98 précitée, il vous est interdit notamment
                d’extraire, réutiliser, stocker, reproduire, représenter ou
                conserver, directement ou indirectement, sur un support
                quelconque, par tout moyen et sous toute forme que ce soit, tout
                ou partie qualitativement ou quantitativement substantielle, du
                contenu des bases de données figurant au site auquel vous
                accédez ainsi que d’en faire l’extraction ou la réutilisation
                répétée et systématique de parties qualitativement et
                quantitativement non substantielles lorsque ces opérations
                excèdent manifestement les conditions d’utilisation normale.
              </Text>
            </Flex>
          </Box>
        </VStack>
      </Container>
    </VStack>
  );
};

export default HeroHome;
