import { Image } from "@chakra-ui/react";
import { iconFooterProps } from ".";

const BaseIconFooter = ({ title, icon, href }: iconFooterProps) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <Image src={icon} w="24px" h="24px" alt={title} />
    </a>
  );
};

export default BaseIconFooter;
