import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast";
import { ApolloProvider } from "@apollo/client";
import client from "./api/index.ts";
import AuthProvider from "./providers/auth.tsx";
import WalletConnect from "./providers/web3Modal.tsx";
import "./firebase";
import "./sentry";

const theme = extendTheme({
  fonts: {
    body: `"Gordita Regular", sans serif`,
  },
  colors: {
    exakugray: {
      100: "#1D262D",
      200: "#FF6D6D",
      300: "#DBDBDB",
      400: "#B3B3B3",
      500: "#161C21",
      600: "#D3D3D3",
      700: "#D9D9D9",
      800: "#0E1318",
    },
    exakured: {
      50: "#FEAAAA",
      100: "#FF4C4C",
      200: "#40474D",
      300: "#F22D2F",
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <WalletConnect>
          <AuthProvider>
            <App />
          </AuthProvider>
        </WalletConnect>
        <Toaster />
      </ChakraProvider>
    </ApolloProvider>
  </React.StrictMode>
);
