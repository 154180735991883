import { Navigate, createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/Home";
import LegalNotices from "../pages/legal";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  // {
  //   path: "/services",
  //   element: <ServicesPage />,
  // },
  // {
  //   path: "/refer/:refer/leg/:leg",
  //   element: <ServicesRedirectPage />,
  // },
  // {
  //   path: "/refer/*",
  //   element: <ServicesRedirectPage />,
  // },
  // {
  //   path: "/shop",
  //   element: <AcademyShop />,
  // },
  // {
  //   path: "/order",
  //   element: <NewAcademyOrder />,
  // },
  // {
  //   path: "/payment/:orderId",
  //   element: <PaymentDetails />,
  // },
  // {
  //   path: "/serenity",
  //   element: <NewSerenityOrder />,
  // },
  // {
  //   path: "/serenity/new",
  //   element: <SerenityPayment />,
  // },
  {
    path: "/legal",
    element: <LegalNotices />,
  },
  // {
  //   path: "/signup",
  //   element: <SignUpMBC />,
  // },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

export default router;
