import { create } from "zustand";

export type UseAccountType = {
  isLoading: boolean;
  error: any;
  list: string[];
  selectedAccount: string | undefined;
  setLoading: (isLoading: boolean) => void;
  setError: (error: any) => void;
  setItems: (items: string[]) => void;
  setSelectedAccount: (account: string) => void;
};

export const useAccounts = create<UseAccountType>((set) => {
  return {
    isLoading: false,
    error: undefined as any,
    list: [] as string[],
    selectedAccount: undefined,
    setLoading: (isLoading: boolean) =>
      set((state) => ({ ...state, isLoading })),
    setError: (error: any) => set((state) => ({ ...state, error })),
    setItems: (accounts: string[]) =>
      set((state) => ({ ...state, list: accounts })),
    setSelectedAccount: (account: string) =>
      set((state) => ({ ...state, selectedAccount: account })),
  };
});
