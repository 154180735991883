import { gql } from "@apollo/client";

export const MUTATION_SIGNUP = gql`
  mutation CreateUser($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      firstName
      lastName
      email
      wallets {
        id
      }
    }
  }
`;

export const MUTATION_SIGNIN = gql`
  mutation WalletSignin($address: String, $message: String) {
    walletSignin(address: $address, message: $message) {
      ... on RequestFailure {
        message
        errors
      }
      ... on SigninSuccess {
        user {
          id
          firstName
          lastName
          email
        }
        token
      }
    }
  }
`;

export const COMPLETE_SIGNUP = gql`
  mutation CompleteSignup($data: SignupCompletionInput) {
    completeSignup(data: $data) {
      ... on RequestFailure {
        code
        errors
        message
      }
      ... on User {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
