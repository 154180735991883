import { Box, Button, Flex, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useReferrer } from "../../store/useReferrer";

const HeaderWithLogin = () => {
  const { getUrl } = useReferrer();

  const goToSigninPage = () => {
    window.location.assign(getUrl());
  };

  return (
    <Box
      h={87}
      w="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Link to="/">
          <Image src="/logo.svg" h={{ base: "30px", md: "40px", lg: "50px" }} />
        </Link>
        <Button
          bg="gray.600"
          color="white"
          fontWeight="700"
          px={5}
          _hover={{ bg: "gray.700" }}
          onClick={goToSigninPage}
        >
          Se connecter
        </Button>
      </Flex>
    </Box>
  );
};

export default HeaderWithLogin;
