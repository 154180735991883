import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBkPOK4eTLKscZd0zGsyTtAVYZX6cxaiwk",
  authDomain: "exaku-2433b.firebaseapp.com",
  projectId: "exaku-2433b",
  storageBucket: "exaku-2433b.appspot.com",
  messagingSenderId: "779716665693",
  appId: "1:779716665693:web:ccb22fc36f1705822a88b4",
  measurementId: "G-398YBNBFEM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
