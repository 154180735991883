function StorageValue(key: string) {
  return {
    key: key,

    get value() {
      if (globalThis.window && localStorage) {
        return localStorage.getItem(this.key) || "";
      }
      return "";
    },

    set value(value: string | undefined) {
      if (globalThis.window && localStorage) {
        localStorage.setItem(this.key, value || "");
      }
    },

    unset() {
      if (globalThis.window && localStorage) {
        localStorage.removeItem(this.key);
      }
    },
  };
}

export const referrer = StorageValue("__kino3ahS__");
export const branch = StorageValue("__Chahng3x__");
export const latestOrder = StorageValue("__ahvaeFing6__");
