import { Box, Container, Text, VStack } from "@chakra-ui/react";
import MainFooter from "../common/MainFooter";

const FooterSection = () => {
  return (
    <VStack minH="fit-content" bg="exakugray.800" py="77px">
      <Container maxW="container.xl">
        <Box fontSize="14px" color="exakugray.400" opacity="0.57">
          <Text as="p">
            By using this website, you understand the information being
            presented is provided for informational purposes only and agree to
            our Terms & Conditions and Privacy Policy. Exaku relies on
            information from various sources believed to be reliable, including
            clients and third parties, but cannot guarantee the accuracy and
            completeness of that information. Nothing in this communication
            should be construed as an offer, recommendation, or solicitation to
            buy or sell any assets or to make any financial
            decisions.Additionally, Exaku or its affiliates do not provide
            financial and tax advice and investors are encouraged to consult
            with their personal advisors. Do your own research.
          </Text>
          <br />
          <Text as="p">
            All investing involves risk, including the possible loss of money
            you invest, and past performance does not guarantee future
            performance. Account holdings and other information provided are for
            illustrative purposes only and are not to be considered investment
            recommendations. The content on this website is for informational
            purposes only and does not constitute a comprehensive description of
            Exaku products and services.
          </Text>
          <br />
          <Text as="p">
            Information provided by Exaku Support is for informational and
            general educational purposes only and is not investment or financial
            advice.
          </Text>
        </Box>
        <MainFooter />
      </Container>
    </VStack>
  );
};

export default FooterSection;
