import { Flex, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Line from "../../assets/images/Vector 4.png";
import AppleStoreImage from "../../assets/images/apple_store.png";
import GooglePlayImage from "../../assets/images/google_play.png";
import { footerLinks, socialNetworks } from "../../constants/footerInfo";
import BaseIconFooter from "../home/BaseIconFooter";

const MainFooter = () => {
  return (
    <>
      <Image src={Line} w="100%" h="auto" my="48px" alt="Line" />
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="flex-start"
        gap={{ base: 10, md: 0 }}
      >
        <Flex
          w={{ base: "100%", md: "auto" }}
          gap={{ base: 0, md: 10 }}
          justifyContent={{ base: "space-between", md: "flex-start" }}
        >
          <Image
            src={AppleStoreImage}
            w={{ base: "160px", md: "172px" }}
            h="auto"
            alt="Apple store"
          />
          <Image
            src={GooglePlayImage}
            w={{ base: "160px", md: "172px" }}
            h="auto"
            alt="Google play"
          />
        </Flex>
        <Flex
          gap="36px"
          alignItems={{ base: "center", md: "flex-start" }}
          w={{ base: "100%", md: "auto" }}
          justifyContent={{ base: "center", md: "flex-start" }}
        >
          {socialNetworks.map((item) => (
            <BaseIconFooter key={item.id} {...item} />
          ))}
        </Flex>
      </Flex>
      <Flex
        mt="36px"
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Flex gap="16px">
          {footerLinks.map((item) => (
            <Link to={item.href} key={item.title}>
              <Text
                as="span"
                color="exakugray.600"
                fontSize="14px"
                _hover={{ opacity: "0.5" }}
              >
                {item.title}
              </Text>
            </Link>
          ))}
        </Flex>
        <Text
          as="span"
          color="white"
          opacity="0.6"
          fontSize="14px"
          textAlign={{ base: "center", md: "right" }}
          mt={{ base: "36px", md: 0 }}
        >
          @ {new Date().getFullYear()} Exaku . All rights reserved
        </Text>
      </Flex>
    </>
  );
};

export default MainFooter;
