import Maintenance from "../../components/maintenance";

const HomePage = () => {
  return (
    <>
      {/* <HeroHome />
      <ExploreSection />
      <ServicesSection />
      <FooterSection /> */}
      <Maintenance />
    </>
  );
};

export default HomePage;
