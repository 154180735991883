import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import toast from "react-hot-toast";
import { COMPLETE_SIGNUP } from "../api/mutations/auth";
import { useApolloClient, useMutation } from "@apollo/client";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
  CompleteSignupMutation,
  CompleteSignupMutationVariables,
  User,
} from "../api/gql/graphql";
import { AuthContextType, SignupData } from ".";
import { useWeb3Connection } from "../store/web3";
import { useAccount } from "wagmi";
import { useAccounts } from "../store/useAccounts";
import { useDisclosure } from "@chakra-ui/react";

export const AuthContext = createContext<AuthContextType>(undefined as any);

export default function AuthProvider(props: PropsWithChildren<{}>) {
  const [_, setLoading] = useState(false);
  const client = useApolloClient();
  const [sendSignup, signupResult] = useMutation<
    CompleteSignupMutation,
    CompleteSignupMutationVariables
  >(COMPLETE_SIGNUP);
  const [tempMethod, setTempMethod] = useState("");
  // const navigate = useNavigate();

  const [emailIntercepted, setEmailIntercepted] = useState("");
  const [email, setEmail] = useState("");
  const [presumedUser, setPresumedUser] = useState<User>();

  const web3 = useWeb3Connection();
  const web3modal = useWeb3Modal();
  const wagmiAccount = useAccount();
  const accounts = useAccounts();

  const [user, setUser] = useState<User>();

  const authMethodPicker = useDisclosure();
  const toastId = useId();

  //   const setAuthenticatedUser = useAuthenticatedUser(
  //     (state) => state.setAuthenticatedUser
  //   );

  const isLoading = useMemo(() => {
    return signupResult.loading;
  }, [signupResult.loading]);

  const hasWeb3Auth = useMemo(() => {
    return Boolean(web3.client) && Boolean(accounts.selectedAccount);
  }, [web3.client, accounts.selectedAccount]);

  const requireSignup = useMemo(() => {
    return Boolean(email) && !presumedUser;
  }, [email, presumedUser]);

  const isCompleted = useMemo(() => {
    return Boolean(hasWeb3Auth && user);
  }, [hasWeb3Auth, user]);

  const signup = useCallback(
    async (data: SignupData) => {
      toast.loading("Veuillez patienter...", {
        id: toastId,
      });
      console.log(data);
      setLoading(true);
      await client
        .mutate<CompleteSignupMutation, CompleteSignupMutationVariables>({
          mutation: COMPLETE_SIGNUP,
          variables: {
            data,
          },
        })
        .then(async (res) => {
          if (res?.data?.completeSignup) {
            let output = res.data.completeSignup;
            if (output.__typename === "User") {
              toast.success("Votre profil a été crée !", { id: toastId });
              setPresumedUser(output);
            } else if (output.__typename === "RequestFailure") {
              toast.error(output.message || "", { id: toastId });
            }
          }
        })
        .catch(() => {
          toast.error("Une erreur est survenue lors de votre inscription", {
            id: toastId,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [sendSignup, toast, toastId]
  );

  const logout = useCallback(() => {
    /// send logout request
  }, []);

  const initAuthentication = useCallback(async (method: string) => {
    setTempMethod(method);
    // if (method === "metamask") {
    //   await web3.setupWithMetamask();
    // } else
    if (method.startsWith("sequence")) {
      let wallet = await web3.setupWithSequence();
      if (wallet) {
        let address = await wallet.getAddress();
        accounts.setItems([address]);
        accounts.setSelectedAccount(address);
      }
    } else if (method.startsWith("walletconnect")) {
      if (!wagmiAccount.address || wagmiAccount.status !== "connected") {
        await web3modal.open();
      }
    }
  }, []);

  useEffect(() => {
    if (accounts.list.length > 0 && !accounts.selectedAccount) {
      accounts.setSelectedAccount(accounts.list[0]);
    }
  }, [accounts.list, accounts.selectedAccount]);

  useEffect(() => {
    if (
      tempMethod === "walletconnect" &&
      wagmiAccount.address &&
      wagmiAccount.status === "connected"
    ) {
      accounts.setItems([wagmiAccount.address]);
      accounts.setSelectedAccount(wagmiAccount.address);
      web3.setupWithWalletconnect(wagmiAccount);
      setTempMethod("");
    }
  }, [tempMethod, wagmiAccount.address, wagmiAccount.status]);

  // useEffect(() => {
  //   if (user) {
  //     setAuthenticatedUser(user);
  //   }
  // }, [user]);

  return (
    <>
      <AuthContext.Provider
        value={{
          user: user,
          isLoading: isLoading,
          hasWeb3Auth,
          requireSignup,
          isCompleted,
          email,
          setEmail,
          emailIntercepted,
          setEmailIntercepted,
          setUser,
          presumedUser,
          setPresumedUser,
          logout,
          signup: async (d) => {
            await signup(d);
          },
          connectPresumedUser() {
            if (presumedUser) {
              setUser(presumedUser);
            }
          },
          connectWallet(method) {
            initAuthentication(method);
          },
          signupAndComeBack() {
            authMethodPicker.onClose();
            let url = `${window.location.pathname}`;
            if (window.location.search) {
              url += window.location.search;
            }
            url = encodeURIComponent(url);
            window.open(`/auth?next=${url}`);
          },
        }}
      >
        {props.children}
      </AuthContext.Provider>
      {/* <AuthMethodPicker
        size="full"
        isOpen={authMethodPicker.isOpen}
        onClose={() => {}}
        onSelected={(method) => {
          authMethodPicker.onClose();
          initAuthentication(method);
        }}
      /> */}
    </>
  );
}
