import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";

export const axiosClient = new axios.Axios();

const apiHosts = {
  //preprod
  1: {
    routes: ["exaku-front-stg-70722dd14dfc.herokuapp.com"],
    resolution: "https://exaku-api-stg-1d66cb971870.herokuapp.com/",
  },
  //prod
  2: {
    routes: ["exaku.com", "www.exaku.com"],
    resolution: "https://exaku-api.herokuapp.com/",
  },
  //default
  default: {
    resolution: "https://exaku-api-stg-1d66cb971870.herokuapp.com/",
  },
};

const frontHosts = {
  //preprod
  1: {
    routes: ["exaku-front-stg-70722dd14dfc.herokuapp.com"],
    resolution: "https://exaku-front-stg-70722dd14dfc.herokuapp.com/",
  },
  //prod
  2: {
    routes: ["exaku.com", "www.exaku.com"],
    resolution: "https://www.exaku.com/",
  },
  //default
  default: {
    resolution: "http://localhost:5173/",
  },
};

const buildUrl = () => {
  for (const key in apiHosts) {
    if (apiHosts.hasOwnProperty(key)) {
      const host = (apiHosts as any)[key];
      if (host.routes) {
        for (const route of host.routes) {
          if (window.location.hostname.includes(route)) {
            return host.resolution;
          }
        }
      }
    }
  }
  return apiHosts.default.resolution;
};

export const frontUrl = () => {
  if (globalThis && globalThis.window) {
    for (const key in frontHosts) {
      const host = (frontHosts as any)[key];
      if (host.routes) {
        for (const route of host.routes) {
          if (window.location.hostname.includes(route)) {
            return host.resolution;
          }
        }
      }
    }
  }
  return frontHosts.default.resolution;
};

const url = buildUrl();

export const api = (segment = "") => {
  return url + segment;
};

const httpLink = createHttpLink({
  uri: api("graphql"),
});

const authLink = setContext((_, prev) => {
  if (globalThis.localStorage) {
    let token = localStorage.getItem("token");
    if (token) {
      return {
        ...prev,
        headers: {
          Authorization: `Bearer ${token}`,
          ...prev.headers,
        },
      };
    }
  }
  return {
    ...prev,
    headers: {
      ...prev.headers,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
